import React from "react"
import { Link } from 'gatsby'
import SEO from '../components/SEO'
import * as styles from './legal.module.less'

const Service = () => (
  <>
    <SEO title="查钻平台服务协议" />

    <main className={styles.container}>
      <h1>查钻平台服务协议</h1>
      {/* </strong>  */}
      <h3>提示条款</h3>
      <p><strong>本协议是您与</strong>查钻<strong>经营者（详见第一条第1款）之间就</strong>查钻<strong>服务等相关事宜所订立的契约，</strong>对您与查钻经营者均具有合同效力。<strong>本协议阐述之条款和条件适用于您使用查钻基于互联网、移动互联网等信息网络，以网站、移动客户端、应用程序等形式向您提供的各项服务（包括未来技术发展出现的新的服务形态）。</strong></p>
      <p>您在申请注册流程中点击同意本协议之前，应当认真阅读本协议。<strong>请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。免除或者限制责任的条款将以粗体下划线标识，您应重点阅读。</strong>如您对协议有任何疑问，可向查钻客服咨询。</p>
      <p>当您按照注册页面提示填写信息、点击“阅读并同意”按钮且完成全部注册程序后，即表示您已充分阅读、理解并接受本协议的全部内容，与查钻经营者达成一致，成为查钻的正式用户，并自愿遵守本协议。<strong>阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止注册程序。</strong></p>

      <section>
        <h3>一、服务条款的确认和接纳</h3>
        <p>1、查钻经营者是指经营查钻的法律主体。<strong>本协议项下，查钻经营者可能根据查钻的业务调整而发生变更，变更后的查钻经营者与您共同履行本协议并向您提供服务，查钻经营者的变更不会影响您本协议项下的权益。发生争议时，您可根据您具体使用的服务及对您权益产生影响的具体行为对象确定与您履约的主体及争议相对方。</strong></p>
        <p>2、查钻各项电子服务的所有权和运作权归属于查钻经营者，查钻提供的服务将完全按照查钻公告的服务条款和操作规则严格执行。您确认同意上述服务条款和操作规则并完成注册程序时，您才能成为查钻正式用户。</p>
        <p>3、注册成功后，即视为您确认自己具有享受在线购买钻石及享受服务等相应的权利能力和行为能力，能够独立承担法律责任。</p>

        <p>4、由于互联网高速发展，您与查钻的本协议列明的条款并不能完整罗列并覆盖您与查钻所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，<strong>查钻法律声明、隐私政策、操作规则均为本协议的补充协议，与本协议不可分割且具有同等法律效力。如您使用查钻服务，视为您同意上述补充协议。</strong></p>
        <p>5、若我们对本协议进行修改，将通过查钻以网站公告的方式予以公布，<strong>您若不认可更新内容，应停止使用查钻服务；若继续使用，则视为您愿意遵守更新后的协议。</strong></p>

        <h3>二、服务内容</h3>
        <p>查钻通过互联⽹依法为您提供商品交易、物流、⾦融等服务，您在完全同意本协议及查钻规定的情况下，⽅有权使⽤查钻的相关服务。</p>

        <h3>三、账户注册、使用</h3>
        <p>1、您应本着诚信向查钻提供注册资料，保证其提供的注册资料真实、准确、完整、合法有效，注册资料如有变动的，应及时更新其注册资料。查钻将依法不时地对您的信息进行检查核实，您应当配合提供最新、真实、完整、有效的信息。否则，您需承担因此引起的相应责任及后果，并且查钻保留终止您使用查钻各项服务的权利。</p>
        <p>2、企业账号认证</p>
        <p>（1）为享有包括采购等更多的服务和平台操作功能，您可以申请企业账号认证。您应按照查钻账号认证要求提供授权书、企业营业执照，以及其他所需资料且经查钻审核通过后，您即为查钻认证后的企业账号。</p>
        <p>（2）您在成功完成企业账号认证后，您可以通过查钻平台在线交易、发布资源信息（商家）、发布求购信息等服务。</p>
        <p>（3）您申请或认证企业账号时，您设置的企业信息不得侵犯或涉嫌侵犯他⼈合法权益。</p>
        <p>3、账号管理</p>
        <p>（1）<strong>用户对通过其账户所进行的操作（包括但不限于在线签署各类协议、发布信息、询/报价、采购产品、订购服务及披露信息等）依法享有权利并自行承担责任，且账号仅限于用户自身进行使用，不得给予任何第三方使用且不能以任何方式转让、赠与或继承，否则由此造成的损失由用户自行承担，且查钻保留暂停或终止服务的权利。</strong></p>
        <p>（2）在完成注册或企业账号认证流程时，您应当按查钻相应页面的提示准确完整地提供并及时更新您的信息，以使之真实、及时，完整和准确。在国家法律法规有明确规定要求查钻作为平台服务提供者必须对用户（如卖家等）的信息进行核实的情况下，查钻将依法不时地对您的信息进行检查核实，您应当配合提供最新、真实、完整、有效的信息。
如查钻按您最后一次提供的信息与您联系未果、您未按查钻的要求及时提供信息、您提供的信息存在明显不实或行政司法机关核实您提供的信息无效的，您将承担因此对您自身、他人及查钻造成的全部损失与不利后果。<strong>查钻可向您发出询问或要求整改的通知，并要求您进行重新认证，直至中止、终止对您提供部分或全部查钻服务。</strong></p>
        <p>（3）您应谨慎合理的保存、使用密码，且勿将密码告知他人。<strong>账户因您主动泄露或因您遭受他人攻击、诈骗等行为导致的损失及后果，查钻并不承担责任，您应通过司法、行政等救济途径向侵权行为人追偿。</strong></p>
        <p>您遗忘或丢失在查钻注册的密码时，可通过注册邮箱号找回密码，也可与查钻客服人员取得联系，在提供相关证明资料并经查钻审核确认后，可找回密码。</p>
        <p>（4）<strong>您同意，查钻有权使用您的注册信息、密码等信息，登陆进入您的注册账户，进行证据保全，包括但不限于公证、见证等。</strong></p>


        <h3>四、⽤户使⽤规范</h3>
        <p>用户在使用查钻服务过程中应遵守如下义务：</p>
        <p>1、在使用查钻服务过程中实施的所有行为均须遵守国家法律、法规，不得违背社会公共利益或公共道德，不得损害他人的合法权益，不违反本协议及相关规则。如果违反前述承诺，产生任何法律后果由用户自行承担责任。</p>
        <p>2、在交易过程中，遵守诚实信用原则，不采取不正当竞争行为，不扰乱查钻服务的正常秩序，不得利用查钻进行非法牟利活动。</p>
        <p>3、在查钻发布的信息不包含以下内容：</p>
        <p>（1）违反国家法律法规禁止性规定的；</p>
        <p>（2）政治宣传、封建迷信、淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的</p>
        <p>（3）欺诈、虚假、不准确或存在误导性的；</p>
        <p>（4）侵犯他人知识产权或涉及第三方商业秘密及其他专有权利的；</p>
        <p>（5）侮辱、诽谤、恐吓、涉及他人隐私等侵害他人合法权益的；</p>
        <p>（6）存在可能破坏、篡改、删除、影响查钻平台任何系统正常运行或未经授权秘密获取查钻平台及其他用户的数据、个人资料的病毒、木马、爬虫等恶意软件、程序代码的；</p>
        <p>（7）其他违背社会公共利益或公共道德或依据相关查钻平台协议、规则的规定不适合在查钻平台上发布的。</p>
        <p>4、除查钻同意外，不得对查钻上的任何数据进行商业性利用，包括但不限于以复制、传播、爬虫抓取等任何方式使用查钻网站上展示的信息。</p>
        <p>5、不使用任何装置、软件或例行程序干预或试图干预查钻的正常运作或正在进行的任何交易、活动。</p>
        <p>6、针对通过查钻取得的商品、服务、软件及技术（包括查钻平台提供的软件及技术），您承诺遵守所有适用的进出口管制、贸易限制与经济制裁相关法律法规。<strong>基于维护查钻交易秩序及交易安全的需要，如您违反前述承诺的，查钻可在发现上述情形时主动执行关闭相关交易订单及作出账户处置等操作。</strong></p>

        <h3>五、用户信息的保护及授权</h3>
        <p>1、用户信息的保护</p>
        <p>保护用户信息（即能够独立或与其他信息结合后识别用户身份的信息）是查钻的一项原则，查钻非常重视用户信息的保护。<strong>在您使用查钻提供的服务时，您同意查钻按照在查钻公布的隐私政策收集、存储、使用、披露和保护您的用户信息。</strong>查钻希望通过 <Link to="/privacy">隐私政策</Link> 向您清楚地介绍查钻对您用户信息的处理方式，因此查钻建议您完整地阅读隐私政策，以帮助您更好地保护您的隐私权。</p>
        <p>2、非用户信息的保证与授权</p>
        <p>（1）您声明并保证，您对您所发布的信息拥有相应、合法的权利。否则，查钻可对您发布的信息依法或依本协议进行删除或屏蔽。</p>
        <p>（2）为方便您使用查钻其他相关服务，您授权查钻将您在账户注册和使用查钻服务过程中提供、形成的信息传递给查钻等其他相关服务提供者，或从查钻等其他相关服务提供者获取您在注册、使用相关服务期间提供、形成的信息。</p>

        <h3>六、商品信息</h3>
        <p>查钻上的商品价格、数量、是否有货等商品信息将根据市场行情及销售情况随时发生变动，查钻不作特别通知。由于网站商品信息数量庞大，虽然查钻会尽最大努力保证您所浏览商品信息的准确性，但由于网络及电脑终端兼容性等客观原因存在，网站网页显示的信息可能会有一定的滞后性或差错，对此情形请您知悉并理解；查钻欢迎纠错，并会视情况给予纠错者一定的奖励。</p>

        <h3>七、违约处理</h3>
        <p>1、<strong>如果发现您违反本协议约定的，查钻有权独立判断并视情节不经通知随时采取警告、拒绝发布、立即对相关内容进行删除、屏蔽，限制或禁止使用部分或全部功能、账号封禁直至注销的处罚，并公告处理结果，并有权根据实际情况决定是否恢复相关账号的使用。</strong>对涉嫌违法、犯罪的行为将保存相关记录，并依法向有关部门报告、配合调查。</p>
        <p>2、<strong>您理解并同意，因您违反本协议规定，导致或产生第三方主张的任何索赔、要求或损失，您应当独立承担责任；查钻因此遭受损失的，您也应当一并赔偿。</strong></p>

        <h3>八、责任限制及不承诺担保</h3>
        <p>1、<strong>查钻平台上展示的全部信息、资料、商品和服务，均由商家自行提供的。 除非另有明确的书面说明,查钻不对平台上的信息、资料、货物和服务作任何形式的、明示或默示的声明或担保（根据中华人民共和国法律另有规定的以外）。 如因不可抗力或其它无法控制的原因导致网上交易无法完成或丢失有关的信息、记录等，查钻会合理地尽力协助处理善后事宜。</strong></p>
        <p>2、<strong>如买卖双方在交易过程中发生纠纷，在当事人自愿平等的前提下，买卖双方可提出要求查钻协助调解。查钻会在查明事实、分清是非的基础上，严格遵守国家法律法规来给出建议。经调解达成的协议具有法律效力，但查钻对此协议内容不承担任何法律责任。</strong></p>
        <p>3、<strong>查钻依照法律规定履行基础保障义务，但对于下述原因导致的合同履行障碍、履行瑕疵、履行延后或履行内容变更等情形，查钻并不承担相应的违约责任：</strong></p>
        <p><strong>（一）因自然灾害、罢工、暴乱、战争、政府行为、司法行政命令等不可抗力因素；</strong></p>
        <p><strong>（二）因电力供应故障、通讯网络故障等公共服务因素或第三人因素；</strong></p>
        <p><strong>（三）在查钻已尽善意管理的情况下，因常规或紧急的设备与系统维护、设备与系统故障、网络信息与数据安全等因素。</strong></p>

        <h3>九、法律适用和争议管辖</h3>
        <p>1、本协议的订立、执行和解释及争议的解决均应适用在中华人民共和国大陆地区适用之有效法律（但不包括其冲突法规则）。</p>
        <p>2、<strong>如缔约方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向有查钻经营者住所地人民法院提起诉讼。</strong></p>

        <h3>十、协议的更新与终止</h3>
        <p>1、协议的更新</p>
        <p> （1）根据国家法律法规变化及网站运营需要，查钻有权对本协议条款不时地进行修改，修改内容将至少在正式实施前三十日予以公示，并征求意见，更新后的协议自发布之日即时生效。</p>
        <p> （2）用户与查钻基于交易合作签署的其他书面协议与本协议不一致的，以双方书面签署的协议为准。</p>
        2、协议的终止
        <p> （1）存在如下情形，查钻有权终止本协议： </p>
        <p> （一）在满足查钻公示的账户注销条件下您向查钻申请注销您的账户的；</p>
        <p> （二）用户违反本协议累计达到3次的；</p>
        <p> （三）其他法律法规规定及查钻认为需要终止的情形。</p>
        <p> （2）<strong>本协议终止后，除法律有明确规定外，查钻无义务向您或您指定的第三方披露您账户中的任何信息。</strong></p>
        <p> （3）<strong>本协议终止后，查钻有权继续保存您的注册信息及在查钻平台上的所有交易信息，并有权视情况删除尚未交易的物品，及未实际履行交易信息。对于您过往的违约行为，淘宝仍可依据本协议向您追究违约责任。</strong></p>
        <p> （4）<strong>本协议终止后，对于您在本协议存续期间产生的交易订单，查钻可通知交易相对方并根据交易相对方的意愿决定是否关闭该等交易订单；如交易相对方要求继续履行的，则您应当就该等交易订单继续履行本协议及交易订单的约定，并承担因此产生的任何损失或增加的任何费用。</strong></p>
        <h3>十一、通知</h3>
        <p> <strong>1、您在注册成为查钻用户，并接受查钻服务时，您应该向查钻提供真实有效的联系方式（包括您的电子邮件地址、联系电话、联系地址等），对于联系方式发生变更的，您有义务及时更新有关信息，并保持可被联系的状态。</strong></p>
        <p><strong>您在注册查钻用户时生成的用于登陆查钻、接收站内信和系统消息的会员账号，也作为您的有效联系方式。</strong></p>
        <p><strong>查钻将向您的上述联系方式的其中之一或其中若干向您送达各类通知，而此类通知的内容可能对您的权利义务产生重大的有利或不利影响，请您务必及时关注。</strong></p>
        <p> <strong>2、查钻通过上述联系方式向您发出通知，其中以电子的方式发出的书面通知，包括但不限于在查钻公告，向您提供的联系电话发送手机短信，向您提供的电子邮件地址发送电子邮件，向您的账号发送系统消息以及站内信信息，在发送成功后即视为送达；以纸质载体发出的书面通知，按照提供联系地址交邮后的第五个自然日即视为送达。</strong></p>
        <p><strong>对于在查钻上因交易活动引起的任何纠纷，您同意司法机关（包括但不限于人民法院）可以通过手机短信、电子邮件等现代通讯方式或邮寄方式向您送达法律文书（包括但不限于诉讼文书）。您指定接收法律文书的手机号码、电子邮箱等联系方式为您在查钻注册、更新时提供的手机号码、电子邮箱联系方式，司法机关向上述联系方式发出法律文书即视为送达。您指定的邮寄地址为您的法定联系地址或您提供的有效联系地址。</strong></p>
        <p><strong>您同意司法机关可采取以上一种或多种送达方式向您达法律文书，司法机关采取多种方式向您送达法律文书，送达时间以上述送达方式中最先送达的为准。</strong></p>
        <p><strong>您同意上述送达方式适用于各个司法程序阶段。如进入诉讼程序的，包括但不限于一审、二审、再审、执行以及督促程序等。</strong></p>
        <p><strong>你应当保证所提供的联系方式是准确、有效的，并进行实时更新。如果因提供的联系方式不确切，或不及时告知变更后的联系方式，使法律文书无法送达或未及时送达，由您自行承担由此可能产生的法律后果。</strong></p>

        <h3>十二、其他</h3>
        <p> 1、本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。</p>
        <p> 2、本协议自发布之日起生效。</p>

        <p style={{ textAlign: 'right' }}>发布日期：2021年04月26日</p>
      </section>
    </main>
  </>
)

export default Service
